import {Injectable} from '@angular/core';
import {DefaultService} from '@core/services/default.service';

@Injectable({
  providedIn: 'root'
})
export class MyLiveCallService extends DefaultService {

  formName: 'is-live-call';

  getUrl(): string {
    return 'call-center/api/v1/live-call-user';
  }

}
